<template>
  <div class="register">
    <div class="preview">
      <img :src="img" />
    </div>

    <div class="imagesregist buttonzona" style="margin-bottom: 30px">
      <a-button
        type="custometwo"
        style="position: absolute; left: 25px; bottom: 50px"
        @click="gallery(1)"
        >1</a-button
      >

      <a-button
        type="custometwo"
        style="position: absolute; left: 75px; top: 10px"
        @click="gallery(2)"
        >2</a-button
      >

      <a-button
        type="custometwo"
        style="position: absolute; left: 130px; bottom: 110px"
        @click="gallery(3)"
        >3</a-button
      >

      <a-button
        type="custometwo"
        style="position: absolute; left: 230px; bottom: 90px"
        @click="gallery(4)"
        >4</a-button
      >

      <a-button
        type="custometwo"
        style="position: absolute; left: 210px; bottom: 180px"
        @click="gallery(5)"
        >5</a-button
      >

      <a-button
        type="custometwo"
        style="position: absolute; left: 170px; bottom: 25px"
        @click="gallery(6)"
        >6</a-button
      >

      <a-button
        type="custometwo"
        style="position: absolute; right: 80px; bottom: 8px"
        @click="gallery(7)"
        >7</a-button
      >
      <img :src="require('@/assets/img/booth-map.png')" />
    </div>
    <a-button type="primary" block @click="back">
      <span style="logout"> <a-icon type="play-circle" /> Kembali </span>
    </a-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/img/0.png"),
    };
  },
  methods: {
    gallery(n) {
      this.img = require("@/assets/img/" + n + ".png");
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
